@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap');

@import url("https://use.typekit.net/mwn4yfc.css");

html, body {
  height: 100%;
}

html, body, footer {
  font-family: 'Montserrat', sans-serif;
}

.font-size-changer{
  display: none !important;
}

$container-max-widths: (
  xl: 1360px
);

@media (max-width: 1200px) {
  .navbar-expand-lg .navbar-toggler {
      display: block !important;
  }
}

@import "~bootstrap/scss/bootstrap";