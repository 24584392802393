#season-01 {
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  &.season-01 {
    .banner {
      background: url('/images/banner-season-01.jpg') #5327f2 no-repeat center center/cover;
      width: 100%;
      height: calc(100vh - 80px);
      
      @media (max-width: 768px) {
        background-image: url('/images/banner-season-01-mobile.png');
      }

      .scroll-down {
        color: #fff;
        width: 28px;
        position: absolute;
        bottom: 4rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        line-height: 1px;
        animation: jump 0.5s linear alternate infinite;
        cursor: pointer;
        & > svg {
          margin-bottom: -1rem;
        }
      }
    }
  }
}
