.extra-content{
    margin-top: 3rem;

    @media(max-width: 600px){
        margin-top: 2rem;
    }

    a{
        &.back {
            text-transform: uppercase;
            color: #222222;
            font-size: 0.85rem;
            text-decoration: underline;
            font-weight: 700;
        }
    }
    &__container{
        border: none;
        &__row{
            &__thumb{
                img {
                    max-width: 100%;
                    border-radius: 1rem;
                    margin-bottom: 37px;
                
                    @media(max-width: 600px){
                        border-radius: 0;
                        margin-top: 0;
                        margin-bottom: 29px;
                    }
                }
            }

            &__description {

                color: #222222;
                margin-bottom: 37px;

                small {
                    font-weight: 800;
                    font-size: 0.813rem;
                }
                h1 {
                    font-weight: 2.25;
                    font-weight: 800;
                    margin-bottom: 1rem;

                    @media(max-width: 600px){
                        font-size: 20px;
                    }
                }
                .tag {
                    text-transform: uppercase;
                    font-size: 0.938rem;
                    color: #7E7E7E;
                    font-weight: 500;
                }
                h4 {
                    font-size: 0.813rem;
                    font-weight: 700;
                }
                p {
                    font-size: 0.813rem; 
                }
            }
        }

        &__about{
            margin-top: 1rem;

            @media (max-width: 600px){
                margin-top: 0;
            }

            h2 {
                font-weight: 800;
                margin-bottom: 1.25rem;

                @media(max-width: 600px){
                    font-size: 18px;
                }
            }
            h3{
                font-weight: 800;
                margin-bottom: 15px;

                @media(max-width: 600px){
                    font-size: 18px;
                }
            }

            p {
                font-size: 1.125rem;
                margin-bottom: 15px;

                @media(max-width: 600px){
                    margin-bottom: 20px;
                }
            }
    
            img {
                max-width: 100%;
                border-radius: 1rem;
                margin-bottom: 62px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            
                @media(max-width: 600px){
                    border-radius: 0;
                }

                &.guest {
                    float: left;
                    border-radius: 0;
                    max-width: 25%;
                    margin: 0 3rem 3rem 0;

                    @media(max-width: 600px){
                        float: none;
                        max-width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }

    }

}