.brands {
    .container {
        flex-wrap: wrap;
        padding-bottom: 3.75rem;
        border-bottom: 1px solid #DEDEDE;
        h4 {
            flex-basis: 100%;
            text-align: center;
            font-size: 2.25rem;
            font-weight: 800;
            margin-bottom: 4rem;
        }
        div {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: 3rem;
            max-width: 25%;
            align-items: center;
            justify-items: center;
            display: flex;
            justify-content: center;

            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: 768px) {
                margin-bottom: 2rem;
                max-width: 100%;
            }
            img {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 1199.98px) {
    .brands {
        .d-flex {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
    }
}