.episodes {
  margin-top: 105px;
  .container {
    padding-bottom: 5rem;
    border-bottom: 1px solid #dedede;
  }
  h1 {
    visibility: hidden;
    height: 0;
    margin: 0;
  }
  header {
    h2 {
      font-weight: 800;
      font-size: 2.25rem;
    }
    .input-group {
      width: auto;
      .input-group-prepend {
        margin-right: 0.5rem;
      }
      .form-control {
        height: auto;
        border: none;
        border-bottom: 1px solid #707070;
        border-radius: 0;
        font-size: 0.813rem;
        padding: 0.1rem 0.75rem;
        text-transform: uppercase;
        padding-left: 0;
        text-align: left;
      }
    }
    .input-group-text {
      border: none;
      padding: 0;
      background: none;
    }
  }
  &__list {
    margin-top: 3rem;
    &__card {
      margin-bottom: 3.75rem;
      border-radius: 1rem;
      border: 1px solid #dedede;
      padding: 0;
      .thumb {
        overflow: hidden;
        border-radius: 1rem 1rem 0 0;
        border-bottom: 1px solid #dedede;
        img {
          max-width: 100%;
        }
      }
      &__description {
        padding: 2rem 1.75rem;
        color: #222222;
        div:last-child {
          margin-left: auto;
        }
        small {
          font-weight: 800;
          font-size: 0.813rem;
        }
        h3 {
          font-size: 1.25rem;
          font-weight: 800;
          margin-bottom: 1.75rem;
          a {
            color: #222222;
          }
        }
        .tag {
          text-transform: uppercase;
          font-size: 0.813rem;
          color: #7e7e7e;
        }
        .play {
          border-radius: 100%;
          text-align: center;
          display: block;
          background-color: #5327f2;
          width: 50px;
          height: 50px;
          color: #fff;
          line-height: 3rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
  @media (max-width: 1199.98px) {
    &__list {
      margin-top: 3rem;
      &__card {
        &__description {
          div:first-child {
            max-width: 75%;
          }
          div:last-child {
            flex: 1 1 auto;
            align-items: center;
          }
          small {
            color: #5327F2;
            font-size: 1.25rem;
            font-weight: 900;
          }
          .play {
            margin: 0 auto 2rem;
          }
        }
      }
    }
  }
}
