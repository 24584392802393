.about{
    .showMobile {
        display: none;
    }
    
    .showDesktop {
        display: block;
    }
    
    @media (max-width: 1199.98px) {
        .showMobile {
            display: block;
        }
        
        .showDesktop {
            display: none!important;
        }
    }

    margin-top: 3rem;
    font-family: 'Montserrat', sans-serif;
    a{
        &.back {
            text-transform: uppercase;
            color: #222222;
            font-size: 0.85rem;
            text-decoration: underline;
            display: block;
            margin-bottom: 2rem;
        }
    }
    &__container{
        &__banner {
            border-radius: 1rem;
            margin-bottom: 9rem;
            width: 100%;
            height: 700px;
            background: url('/images/about-banner.jpg') no-repeat center center;
            & > img {
                display: none;
            }
        }
        .row {
            align-items: center;
        }
    }
    &__block{
        &.block-01 {
            h1 {
                visibility: hidden;
                height: 0;
                margin: 0;
            }
            img {
                width: 100%;
            }
        }
        &.block-02 {
            min-height: 580px;

            img {
                width: 100%;
            }
        }
        p {
            font-size: 1.125rem;
            font-weight: lighter;
        }
    }

    .hosts {
        padding: 1.4rem 0;
        background-color: #5327F2;
        color: #fff;
        &__wrap {
            &__ana{                    
                min-height: 890px;
                padding-top: 15rem;
                .row { 
                    flex-direction: row;

                    & > div {
                        margin-right: 0;
                    }
                }
            }

            &__ricardo{
                flex-direction: row;

                min-height: 890px;
                padding-top: 15rem;
                .row { 
                    flex-direction: row-reverse;

                    & > div {
                        margin-right: 0;
                    }
                }
            }
        }

        img {
            width: 100%;
        }

        h3 {
            font-size: 2.25rem;
            margin-bottom: 3.5rem;
            font-weight: 800;
        }
        h2 {
            margin-bottom: 2rem;
        }
        p {
            font-size: 1.125rem;
            font-weight: lighter;
        }
    }

    @media (max-width: 1199.98px) {
        margin: 0;
        .banner-wrap{
            padding: 0;
        }
        &__container{
            & > a.back {
                display: none;
            }

            .row {
                flex-direction: column !important;
                align-items: center;
            }
            &__banner {
                margin-bottom: 9rem;
                width: 100%;
                height: 65vh;
                border-radius: 0;
                background: url('/images/mercado-e-seu.png') no-repeat no-repeat -260% 105%/85%, url('/images/mercado-e-seu.png') no-repeat 320% -25%/85%, url('/images/about-banner-mobile.jpg') no-repeat center center / cover;
                // background-color: #5327F2;
                position: relative;
                & > img {
                    display: block;
                    position: absolute;
                    width: 300px;
                    left: 1rem;
                    top: 73%;
                }
            }
        }
        &__block{
            z-index: 0;
            position: relative;
            &.block-01 {
                h1 {
                    display: inline-block;
                    margin-bottom: 2rem;
                    width: 100%;
                    font-weight: 800;
                    text-align: center;
                    font-size: 1.75rem;
                }
                padding-top: 0;
                background: none;
                min-height: 620px;
            }
            &.block-02 {
                margin-top: 10rem;
            }
            p {
                font-size: 1rem;
                margin-bottom: 2rem;
            }
        }
        .hosts {
            z-index: 0;
            margin-top: -18rem;
            padding-bottom: 5rem;
            p {
                font-size: 1rem;
                margin-bottom: 2rem;
            }
            &__wrap {
                h3{
                    width: 100%;
                    display: inline-block;
                    text-align: center;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 1.5rem;
                    font-size: 1.75rem;
                }
                h2 {
                    text-align: center;
                    font-weight: 700;
                    font-size: 1.75rem;
                }
                
                &__ana{
                    min-height: 890px;
                    padding-top: 15rem;
                    .row { 
                        flex-direction: column !important;
                        align-items: center;
                        & > div {
                            margin-right: 0;
                        }
                    }
                }
    
                &__ricardo{
                    min-height: 890px;
                    padding-top: 5rem;
                    .row { 
                        flex-direction: column !important;
                        align-items: center;
                        & > div {
                            margin-bottom: 45px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}