footer {
  text-transform: uppercase;
  font-size: 0.813rem;
  font-weight: 500;
  padding: 3rem 0;

  @media (min-width: 992px) {
    padding: 6rem 0;
  }

  .container {
    width: 100%;

    @media (min-width: 1200px) {
      width: 50%;
    }

    & > p {
      color: #7e7e7e;
      margin-top: 3rem;
      font-size: 1rem;
      text-transform: initial;
    }
  }
  p {
    line-height: 1rem;
    margin: 0;
  }
  .logo {
    vertical-align: super;
    display: inline-block;
    
    img {
      max-width: 170px;
    }
  }
  .divisor {
    width: 1px;
    height: 31px;
    background-color: #dedede;
    vertical-align: middle;
    display: inline-block;
    margin: 0 2rem;
  }
  .social {
    p, a {
      display: inline-block;
    }
    a {
      background-color: #1127aa;
      width: 31px;
      height: 31px;
      margin: 0 0.5rem;
      border-radius: 100%;
      text-align: center;
      color: #fff;
      line-height: 2rem;
    }
  }
  .copyright {
    width: 75%;
  }
}