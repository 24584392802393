.episode {
  margin-top: 3rem;
  a {
    &.back {
      text-transform: uppercase;
      color: #222222;
      font-size: 0.85rem;
      text-decoration: underline;
    }
  }

  &__audio {
    min-width: 98%;
    display: block;
    margin: 20px auto;
  }

  &__container {
    &__row {
      @media (max-width: 766px) {
        flex-direction: column;
      }
      &__thumb {
        img {
          max-width: 100%;
          border-radius: 1rem;
        }
      }

      &__description {
        padding: 2rem 1.75rem;
        color: #222222;
        small {
          font-weight: 800;
          font-size: 0.813rem;
        }
        h1 {
          font-weight: 2.25;
          font-weight: 800;
          margin-bottom: 1.75rem;
        }
        .tag {
          text-transform: uppercase;
          font-size: 0.938rem;
          color: #7e7e7e;
          font-weight: 500;
        }
        .guest {
          font-size: 0.813rem;
          font-weight: 700;
          display: block;
        }
        p {
          font-size: 0.813rem;
        }
      }
    }

    &__about {
      margin-top: 2rem;
      .title {
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 800;
        margin-bottom: 1.25rem;
      }
      p {
        font-size: 1.125rem;
      }

      img {
        display: inline-block;
        max-width: 170px;
      }
    }

    &__transcription {
      margin-top: 2rem;
      .title {
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 800;
        margin-bottom: 1.25rem;
      }
      .content {
        height: 30rem;
        overflow-y: scroll;
        margin: 3rem 2.5% 0;
        padding: 1rem 5% 0 2.5%;
        p {
          font-size: 1.125rem;
          margin-bottom: 2rem;
        }
      }
      .content::-webkit-scrollbar {
        width: 12px;
        background: #e0e0e0;
      }
      .content::-webkit-scrollbar-track {
        border: 5px solid #ffffff;
      }
      .content::-webkit-scrollbar-thumb {
        background: #101010; 
        border-radius: 7px;
      }
      .content::-webkit-scrollbar-thumb:hover {
        background: #606060; 
      }
    }
  }
}
