.sign-language {
    position: fixed;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    right: 0;
    z-index: 9;
    text-align: center;
    top: 35%;
    transform: translateY(-50%);

    .btn {
        max-width: 48px;
        padding: 0;
        margin: 0.50rem 0;
        border-radius: 0;
        &.sign-language__close {
            padding-bottom: 1rem;
            border-bottom: 2px solid #5327F2;
        }
    }
    
    .ht-skip {
        & > div {
            top: 0;
            & > div {
                margin-top: 330px;
            }
        }
    }
}

.sign-language--hidden{
    display: none;
}

@media (max-width: 1366px) {
    .sign-language {
        padding: 0.7rem;
        .btn {
            max-width: 32px;
            &.sign-language__close {
                padding-bottom: 0.7rem;
            }
            svg {
                width: 32px;
                height: 32px;
            }
        }
        .ht-skip {
            & > div {
                top: calc(50% + 140px);
                bottom: unset;
                & > div {
                    margin-top: 0;
                }
            }
        }
    }
}