.extra-content {
  margin-top: 105px;
  padding-bottom: 3rem;
  .container {
    // border-bottom: 1px solid #dedede;

    &--no-border {
      border: none;
    }
  }
  header {
    h2 {
      font-weight: 800;
      font-size: 2.25rem;
    }
  }
  &__list {
    margin-top: 3rem;
    &__card {
      margin-bottom: 3.75rem;
      border-radius: 1rem;
      padding: 0;
      .thumb {
        overflow: hidden;
        border-radius: 1rem;
        height: 240px;
        img {
          width: 100%;
        }
      }
      &__description {
        padding: 2rem 1.75rem;
        color: #222222;
        h3 {
          font-size: 1.25rem;
          font-weight: 800;
          margin-bottom: 1rem;
          a {
            color: #222222;
          }
        }
        .tag {
          text-transform: uppercase;
          font-size: 0.813rem;
          color: #7e7e7e;
        }
      }
    }
  }
}
