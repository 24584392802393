#trampapo-banner {
  @keyframes jump {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  background: url("/images/banner-home.jpg") no-repeat center center;
  width: 100%;
  height: calc(100vh - 80px);
  margin: 0;
  color: #fff;
  padding: 0 9rem;
  h1 {
    height: 0;
    visibility: hidden;
    margin: 0;
  }
  img {
    max-width: 100%;
    display: inherit;
  }
  h2 {
    font-size: 1.75rem;
  }
  & > div {
    max-width: 720px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .social-buttons {
    font-size: 0.95rem;
    font-weight: bold;
    flex-wrap: wrap;
    & > .btn {
      flex: 1 0 30%;
      padding: 0;
      align-items:center;
      justify-content: center;
      height: 55px;
      display: flex;
      margin: 0 0.5rem 1rem;
      max-width: 220px;
      font-weight: bold;
      font-size: 1rem;
      .fa-lg {
        margin-right: 0.5rem;
      }

      svg {
        transition: all 0.15s;
        margin-right: 5px;
      }

      &:hover {
        svg {
          fill: #000;
        }
      }

      @media (max-width: 1620px) {
        font-size: 0.7rem;

        svg {
          width:2rem;
          height: 1rem;
        }
      }
      
    }
  }
  .scroll-down {
    color: #fff;
    width: 28px;
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 1px;
    animation: jump 0.5s linear alternate infinite;
    cursor: pointer;

    & > svg {
      margin-bottom: -1rem;
    }
  }

  .listen-now {
  }

  @media (max-width: 1366px) {
    padding: 0 2rem;
    & > div {
      max-width: 585px;
    }
  }

  @media (max-width: 1024px) {
    background: url("/images/banner-home-mobile.jpg") #5327f2 no-repeat center center / contain;
    align-items: start !important;
    background-position-y: 20%;
    padding: 20px 15px;
    max-height: 665px;
    min-height: 620px;
    & > div {
      max-width: 100%;
      width: 100% !important;
      height: 100%;
      position: relative;
      img {
        max-width: 80%;
        margin: 0 auto 2rem !important;
      }
      h2 {
        padding: 0 2rem;
        text-align: center;
        font-size: 90%;
        font-family: "DINosaur", sans-serif;
        font-weight: bold;
      }
    }
    .listen-now {
      position: absolute;
      bottom: 0;
      height: auto;
    }
    .scroll-down {
      display: none;
    }
    .social-buttons {
      font-size: 80%;
      font-weight: bold;
      flex-wrap: wrap;
      justify-content: space-between;
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 5px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > .btn {
        flex: 1 0 30%;
        padding: 0.5rem 0rem;
        margin: 0;
        max-width: 220px;
        font-weight: bold;
        font-size: 8px;
        padding: 9px 0;
        flex-wrap: wrap;
        flex: initial;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .fa-lg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.trampapo-banner {
  &__wrapper {
    @media (max-width: 1199.98px) {
        align-items: start !important;
        padding-top: 1.5rem;
        & > div {
            max-width: 100%;
            width: 100% !important;
            height: 100%;
            img {
                max-width: 80%;
                margin: 0 auto 2rem;
            }
            h2 {
                padding: 0 2rem;
                text-align: center;
                font-size: 90%;
                font-family: 'dinosaur', sans-serif;
                font-weight: bold;
            }
        }
        .scroll-down {
            display: none;
        }
        .social-buttons {
            font-size: 80%;
            font-weight: bold;
            flex-wrap: wrap;
            & > .btn {
                flex: 1 0 30%;
                padding: 0.5rem 0rem;
                margin: 0 0.5rem 1rem;
                max-width: 220px;
                font-weight: bold;
                font-size: 80%;
                .fa-lg {
                    margin-right: 0.5rem;
                }
            }
        }
    }
  }
}
