.newsletter{
    color: #fff;
    padding-top: 5.62rem;
    padding-bottom: 6rem;
    margin-bottom: 6rem;
    background: url('/images/bg-balao-01.png') no-repeat 95% 30%, url('/images/bg-balao-02.png') no-repeat 10% 65%;
    background-color: #5327F2;
    h4 {
        font-weight: 800;
        margin-bottom: 3rem;
    }
    p {
        padding: 0 6.5rem;
    }
    &__container {
        &__form {
            margin-top: 4rem;
            .form-control {
                background-color: transparent;
                border: none;
                font-size: 0.85rem;
                border-radius: 0;
                border-bottom: 1px solid #fff;
                color: #BAA8FA;
                &::placeholder {
                    color: #BAA8FA;
                }
                &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
            .btn {
                background-color: #00ECCF;
                color: #5327F2;
                font-weight: 800;
                padding: 0.689rem 1.9rem;
                svg {
                    margin-right: 1rem;
                    vertical-align: middle;
                }
            }
        }
    }
    @media (max-width: 1199.98px) {
        background-image: none;
        &__container {
            & > div {
                width: 100% !important;
            }
            &__form {
                .form-row {
                    & > div {
                        width: 100%;
                        margin: 1.5rem 0;
                        .btn {
                            width: 100%;
                            padding: 1rem 1.9rem;
                        }
                    }
                }
            }
        }
        p {
            padding: 0 2.95rem;
            font-size: 1rem;
        }
    }
}