.social-share{
    background-color: #5327F2;
    color: #fff;
    padding: 5rem 0;
    margin-top: 5rem;

    @media (max-width: 992px) {
        padding: 4rem 0;
    }

    .title {
        font-weight: 800;
        font-size: 2.25rem;
    }
    .social-icons {
        a {
            display: inline-block;
            width: 60px;
            height: 60px;
            border: 1px solid #fff;
            border-radius: 100%;
            text-align: center;
            line-height: 3.56rem;
            font-size: 1.6rem;
            color: #fff;
            margin: 0 0.7rem;
        }
    }
    .btn {
        background-color: #00ECCF;
        color: #5327F2;
        font-weight: 800;
        padding: 0.689rem 1.9rem;
        svg {
            margin-right: 1rem;
            vertical-align: middle;
        }
    }
}