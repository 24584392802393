nav {
    &.navbar  {
        background-color: #fff;
        box-shadow: 0px 7px 13px 0px rgba(0, 0, 0, 0.3);
        padding: 1.074rem 3rem;
        .navbar-toggler{
            &:focus{
                outline: none;
            }
        }
        .navbar-brand{
            img {
                max-width: 206px;
            }
        }
        a {
            &.nav-link{
                font-weight: 700;
                color: #222222;
                margin: 0 1rem;
                font-size: 0.9378rem;
                text-transform: uppercase;
                padding: 0;
                &.home {
                    display: none;
                }
            }
        }
        .navbar-toggler-icon {
            background: url('/images/navbar-icon.png') no-repeat center center / contain;
        }
    }
    .nav-right {
        .logo {
            margin-right: 2rem;
            img {
                max-width: 160px;
            }
        }
        a {
            margin: 0 0.5rem;
            &:not(.logo){
                background-color: #222222;
                width: 31px;
                height: 31px;
                border-radius: 100%;
                text-align: center;
                color: #fff;
                line-height: 2rem;
            }
        }
        &.mobile {
            display: none !important;
        }
    }
    
    @media (max-width: 1600px) {
        &.navbar  {
            padding: 1.074rem 1rem;
            a {
                &.nav-link{
                    margin: 0 0.5rem;
                    font-size: 80%;
                }
            }
        }
    }

    @media (max-width: 1199.98px) {
        &.navbar  {
            width: 100%;
            margin: auto !important;
            .navbar-nav {
                display: flex;
                height: 60vh;
                flex-direction: column;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 6vh;
            }
            a {
                &.nav-link{
                    color: #fff;
                    font-weight: 700;
                    font-size: 1.375rem;
                    text-align: center;
                    &.home {
                        display: inline;
                    }
                }
            }
        }
        .collapse:not(.show) {
            height: 0vh;
            overflow: hidden;
        }
        .navbar-toggler:not(.collapsed) {
            position: absolute;
            z-index: 9;
            right: 1.75rem;
            width: 41px;
            height: 41px;
            .navbar-toggler-icon {
                background: url('/images/close-icon.png') no-repeat center center;
            }
        }
        .navbar-toggle{
            .collapsed {
                .navbar-toggler-icon{
                    &:before {
                        content: '\f00d';
                    }
                } 
            }
        }
        .navbar-collapse {
            transition: all linear 0.2s;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: #5327F2;
            color: #fff;
            flex-direction: column;
            .navbar-nav {
                margin: auto !important;
            }
        }
        .nav-right {
            display: none !important;
            & > a {
                &:not(.logo){
                    background-color: transparent;
                    text-align: center;
                    color: #fff;
                    font-size: 0.83rem;
                    width: 100%;
                    margin-bottom: 3rem;
                    text-transform: uppercase;
                }
            }
            & > div {
                a   {
                    &:not(.logo) {
                        margin: 1rem 0.5rem 0;
                        background-color: #00ECCF;
                        width: 41px;
                        height: 41px;
                        border-radius: 100%;
                        text-align: center;
                        color: #5327F2;
                    }
                    &.facebook {
                        font-size: 1.4rem;
                        line-height: 2.5rem;
                    }
                    &.instagram {
                        font-size: 1.75rem;
                        line-height: 2.5rem;
                    }
                    &.youtube {
                        font-size: 1.4rem;
                        line-height: 2.5rem;
                    }
                }
            }
            &.mobile {
                display: flex !important;
                flex-direction: column;
                margin-top: 2rem;
            }
        }
    }
}

.link-scroll{
    cursor: pointer!important;
}